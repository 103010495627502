import React, { Component } from "react";

import _ from 'lodash';
import { graphql } from 'gatsby'

import Layout from 'components/Page/Support/Layout';
import Section from 'components/Page/Support/Section';
import Block from 'components/Blocks';


class DocSection extends Component {
  
    render() {
      const docCategory = this.props.data.contentfulDocCategory
      const blocks = this.props.data.allContentfulBlock.nodes
      const categories = this.props.pageContext.categories
      
      return ( 
        <Layout>
          <Section data={docCategory} categories={ categories } currentCategory={ docCategory.id } />

          <Block data={ _.find(blocks, (b) => b.blockId === 'SupportFormHeader' ) } />
          <Block data={ _.find(blocks, (b) => b.blockId === 'SupportForm' ) } />
        </Layout>
      )
    }
}

export default DocSection

// language=GraphQL
export const docCatQuery = graphql`
  query DocCategoryQuery($id: String!, $blocks: [String]!) {
    contentfulDocCategory(id: { eq: $id }) {
      id
      title
      docs {
        ...on ContentfulDoc {
          id
          title
        }
      }
    }

    allContentfulBlock(filter: {blockId: {in: $blocks}}) {
      nodes {
        ...BlockFields
      }
    }
  }
`;