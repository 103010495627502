import React, { Component } from 'react';
import slugify from 'lib/slugify';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Link } from 'gatsby';

const NavContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
  padding-bottom:5rem;
  margin-bottom:5rem;
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};

  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;

const NavLink = styled(Link)`
  font-size:1.8rem;
  font-weight:300;
  display:block;
  ${p => p.selected ? 'text-decoration:underline;font-weight:bold;' : null};
  color:${p => p.theme.cardTextColor};

  ${media.lessThan("tablet")`
    padding:1rem;
    margin:0.5rem 0;
    border:0.1rem solid ${p => p.theme.cardBorderColor};
    ${p => p.selected ? 'text-decoration:none;font-weight:400;background-color:rgba(255, 255, 255, 0.1)' : null};
    color:${p => p.theme.cardTextColor};
  `}
`;

class Nav extends Component {
  render() {
    const category = this.props.category;
    const categories = this.props.categories;

    return (
      <NavContainer>
        {categories && categories.map((c) => (
          <NavLink selected={category && (category.id === c.id)} to={ `/docs/category/${slugify(c.title)}` } key={`cat_${c.id}`}>{ c.title }</NavLink>
        ))}
      </NavContainer>
    )
  }
}

export default Nav