import React, { Component } from 'react';
import slugify from 'lib/slugify';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Link } from 'gatsby';
import Nav from './Nav';

const Container = styled.div`
  
`;


const Articles = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:5rem 0;

  ${media.lessThan("tablet")`
    padding:0;
  `}
`;

const Article = styled.div`
  width:70rem;
  margin-bottom:2rem;
  padding-bottom:2rem;
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};

  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const ArticleLink = styled(Link)`
  font-size:1.8rem;
  font-weight:300;
  display:block;
  color:${p => p.theme.cardTextColor};
`;

class ArticleList extends Component {
  render() {
    const category = this.props.data;
    const categories = this.props.categories;

    return (
      <Container>
        <Nav category={ category } categories={ categories } />

        <Articles>
          {category.docs.map((doc) => (
            <Article>
              <ArticleLink to={`/docs/${slugify(doc.title)}`}>{doc.title}</ArticleLink>
            </Article>
          ))}
        </Articles>
      </Container>
    )
  }
}

export default ArticleList